import { render, staticRenderFns } from "./dropzone.vue?vue&type=template&id=32a3df1d&"
import script from "./dropzone.vue?vue&type=script&lang=ts&"
export * from "./dropzone.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/deploy/scalefish/releases/120/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('32a3df1d')) {
      api.createRecord('32a3df1d', component.options)
    } else {
      api.reload('32a3df1d', component.options)
    }
    module.hot.accept("./dropzone.vue?vue&type=template&id=32a3df1d&", function () {
      api.rerender('32a3df1d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "assets/js/components/file/dropzone.vue"
export default component.exports